import { ContentCopy } from '@mui/icons-material';
import { useState } from 'react';
import { TimelineItem } from '../../types/Timeline';
import { CircularProgress } from '@mui/material';
import axios from "axios";

const READ_API_URL = process.env.REACT_APP_READ_API_URL;
const WRITE_API_URL = process.env.REACT_APP_WRITE_API_URL;

interface TimelineProps {
  data: TimelineItem[];
  loading: boolean;
}

const Timeline = ({ data, loading }: TimelineProps) => {
  if (loading) {
    return (
      <div className="p-3">
        <div className="flex justify-center items-center">
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="p-3">
      <table className="table-fixed w-full border-slate-300 border-[1px]">
        <thead>
          <tr className="underline">
            <th className="text-left p-2">Action</th>
            <th className="text-center p-2">Timestamp</th>
            <th className="text-right p-2">Status Bucket</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <Row key={index} item={item} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Row = ({ item }: { item: TimelineItem }) => {
  const { action, timestamp, status, image, filename, presigned_url, downloadable, renderable , message, details, message_uuid} =
    item;
  const [copied, setCopied] = useState(false);

  const openPdf = (e: any) => {
    const newTab = window.open(presigned_url, '_blank');
    if (newTab) {
      e.preventDefault();
      newTab.focus();
    }
  };

  const copyFilename = () => {
    // @ts-ignore
    navigator.clipboard.writeText(filename);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <tr className="border-slate-200 border-b-[1px] odd:bg-[#f9f9f9] last:border-b-0">
      {!!presigned_url && !!filename && (
        <td className="text-left p-2">
          <span>{action}</span>
          <span
            className="ml-2 hover:cursor-pointer"
            title="Copy Filename"
            onClick={copyFilename}
          >
            <ContentCopy fontSize="small" />
            {copied && <span className="text-green-500 animate-fade-out">Copied!</span>}
          </span>
          {renderable && <img
              src={presigned_url}
              alt="Descriptive alt text"
              className="your-image-class" // Optional: add your desired classes

          />}
          {downloadable ? (
              <button
                  className="text-sky-600 underline hover:text-sky-500 max-w-full break-words block"
                  onClick={openPdf}
              >
                {filename}
              </button>
          ) : (
              !renderable &&
                <span className="max-w-full break-words block">{filename}</span>
          )}
        </td>
      )}
      {!!image && !presigned_url && (
        <td className="text-left p-2">{<img src={image} alt="Action" />}</td>
      )}
      {!!message && (<td className="text-left p-2"  title={details || ''}>{message} {!!message_uuid && (<div><button className="bg-gray-600 hover:bg-gray-500 text-white p-2 rounded ml-auto mt-3" onClick={async () => {
        // call api
        if (window.confirm("Are you sure?")) {
          await axios.post(
              `${WRITE_API_URL}/api/v1/admin/resend_message`,
              {
                message_id: message_uuid
              }
          );
        }

      }}>Resend Message</button></div>)}</td>)}
      {!presigned_url && !image && !message && <td className="text-left">{action}</td>}
      <td className="text-center p-2">
        {new Date(timestamp).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: '2-digit',
          hour: 'numeric',
          minute: 'numeric'
        })}
      </td>
      {/* @ts-ignore */}
      <td className="text-right p-2">{status}</td>
    </tr>
  );
};

export default Timeline;
