import { useContext, useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';
import CircularProgress from '@mui/material/CircularProgress';
import { ShipmentType, ShipmentStatusType } from '../../types/Shipment';
import { useLinkClickHandler } from 'react-router-dom';
import axios from 'axios';
import { exportShipments } from '../../utils/exportShipments';
import { UserContext } from '../../App';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal } from '@mui/material';
import { twMerge } from 'tailwind-merge';
const READ_API_URL = process.env.REACT_APP_READ_API_URL;

const Shipments = () => {
  // @ts-ignore
  const { user } = useContext(UserContext);
  const [shipments, setShipments] = useState<ShipmentType[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [sortColumn, setSortColumn] = useState(0);
  const [sortDirection, setSortDirection] = useState(0);
  const [total, setTotal] = useState(0);
  const [shipFromList, setShipFromList] = useState([]);
  const [shipToList, setShipToList] = useState([]);
  const [carrierList, setCarrierList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [shipTo, setShipTo] = useState([]);
  const [shipFrom, setShipFrom] = useState([]);
  const [carrier, setCarrier] = useState([]);
  const [dateType, setDateType] = useState('');
  const [dateRange, setDateRange] = useState<any>();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const hasSearch =
    search || shipFrom.length || shipTo.length || carrier.length || dateRange;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const response = await axios.get(`${READ_API_URL}/api/v1/admin/shipments_metadata`);

  //     setShipFromList(response.data.ship_from);
  //     setShipToList(response.data.ship_to);
  //     setCarrierList(response.data.carrier);
  //     setLoading(false);
  //   };

  //   if (!!user) {
  //     fetchData();
  //   }
  // }, [user]);
  useEffect(() => {
    if (hasSearch) {
      getShipments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortColumn, sortDirection]);

  const getShipments = async () => {
    setLoading(true);
    let queryString = `${READ_API_URL}/api/v1/admin/shipments?page=${page}&per_page=${perPage}&search=${search}&sort_column=${sortColumn}&sort_direction=${sortDirection}&ship_from=${shipFrom.join(
      ','
    )}&ship_to=${shipTo.join(',')}&carrier=${carrier.join(',')}`;
    if (dateRange?.start && dateRange?.end) {
      queryString += `&date_type=${dateType}&start_date=${dateRange.start
        .toISOString()
        .substr(0, 10)}&end_date=${dateRange.end.toISOString().substr(0, 10)}`;
    }
    const response = await axios.get(queryString);
    setShipments(response.data.data);
    setTotal(response.data.metadata.total);
    setTotalPages(
      Math.ceil(response.data.metadata.total / response.data.metadata.per_page)
    );
    setLoading(false);
  };

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value.toUpperCase());
  };

  const maxPagesToShow = 5;
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={i === page ? 'active underline font-bold' : 'hover:text-slate-400'}
          onClick={() => setPage(i)}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handleHeaderClick = (column: number) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 0 ? 1 : 0);
    } else {
      setSortColumn(column);
    }
  };

  const handleExport = async () => {
    setExporting(true);
    try {
      await exportShipments();
    } catch (error) {
      console.error('Error exporting shipments', error);
    } finally {
      setExporting(false);
    }
  };

  const handleDateRangeChange = (option: any) => {
    if (option?.value === 'custom') {
      setDateRange(null);
      setShowDatePicker(true);
    } else if (!!option) {
      const [rangeType, rangeValue] = option.value.split('_');

      if (rangeType === 'next') {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + parseInt(rangeValue, 10));

        const range = {
          start: new Date(),
          end: endDate
        };

        setDateRange(range);
      } else if (rangeType === 'last') {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() - parseInt(rangeValue, 10));

        const range = {
          start: endDate,
          end: new Date()
        };

        setDateRange(range);
      }
      if (!dateType) {
        setDateType('estimated');
      }
    } else {
      setDateRange(null);
      setDateType('');
    }
  };

  const handleCustomDateRangeChange = (dates: any) => {
    const [start, end] = dates;
    const range = {
      start: start,
      end: end
    };

    if (!dateType) {
      setDateType('estimated');
    }
    setDateRange(range);
  };

  const handleSearch = async () => {
    if (hasSearch) {
      setError('');
      if (search[0] !== 'T') {
        setError('Please enter a search term starting with T');
        return;
      }
    } else {
      setError('Please enter a search term or select a filter');
      return;
    }

    if (!loading && hasSearch) {
      getShipments();
    }
  };

  const shipFromOptions = shipFromList.map((shipFrom: string) => {
    return { value: shipFrom, label: shipFrom };
  });

  const shipToOptions = shipToList.map((shipTo: string) => {
    return { value: shipTo, label: shipTo };
  });

  const carrierOptions = carrierList.map((carrier: string) => {
    return { value: carrier, label: carrier };
  });

  const dateTypeOptions = [
    { value: 'estimated', label: 'Est. Ship Date' },
    { value: 'actual', label: 'Actual BOL Date' }
  ];

  const dateRangeOptions = [
    { value: 'next_1', label: 'Next 24 Hours' },
    { value: 'last_1', label: 'Last 24 Hours' },
    { value: 'next_7', label: 'Next 7 Days' },
    { value: 'last_7', label: 'Last 7 Days' },
    { value: 'last_30', label: 'Last 30 Days' },
    { value: 'last_90', label: 'Last 90 Days' },
    { value: 'custom', label: 'Custom' }
  ];

  return (
    <div className="p-5 w-full">
      <Modal
        open={showDatePicker}
        onClose={() => setShowDatePicker(false)}
        className="flex justify-center items-center"
      >
        <div className="min-h-80">
          <DatePicker
            selected={dateRange?.start}
            onChange={handleCustomDateRangeChange}
            startDate={dateRange?.start}
            endDate={dateRange?.end}
            selectsRange
            inline
          >
            <div className="w-full text-right">
              <button
                className="bg-sky-600 hover:bg-sky-500 text-white p-2 h-[38px] rounded"
                onClick={() => setShowDatePicker(false)}
              >
                Close
              </button>
            </div>
          </DatePicker>
        </div>
      </Modal>
      <div className="bg-[#FBFBFB] p-3 shadow-[1px_0_10px_1px_rgba(0,0,0,0.3)] text-sm">
        <div className="flex justify-between mb-2">
          <div className="w-full">
            <div className="flex gap-2 items-center">
              {/* Filter By: */}
              {/* <Select
                options={[{ value: 'test', label: 'Test' }]}
                isClearable
                placeholder="Status"
                className="min-w-40"
              /> */}
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    display: 'none'
                  })
                }}
                options={dateTypeOptions}
                isSearchable={false}
                isClearable
                placeholder="Date Type"
                className="min-w-40"
                value={dateTypeOptions.find((option) => option.value === dateType)}
                onChange={(selectedOption: any) => {
                  setDateType(selectedOption?.value);
                }}
              />
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    display: 'none'
                  })
                }}
                options={dateRangeOptions}
                isSearchable={false}
                isClearable
                placeholder="Date Range"
                className="min-w-40"
                onChange={handleDateRangeChange}
                formatOptionLabel={(option: any, selectedOption: any) => {
                  if (
                    option.value === 'custom' &&
                    dateRange &&
                    selectedOption?.selectValue[0]?.value === 'custom'
                  ) {
                    const startDate = new Date(dateRange.start);
                    const endDate = new Date(dateRange.end);
                    const formattedStartDate = startDate.toLocaleDateString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: '2-digit'
                    });
                    const formattedEndDate = endDate.toLocaleDateString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: '2-digit'
                    });
                    return `${formattedStartDate}-${formattedEndDate}`;
                  } else {
                    return option.label;
                  }
                }}
              />
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    display: 'none'
                  })
                }}
                options={carrierOptions}
                isSearchable
                isClearable
                isMulti
                placeholder="Carrier"
                className="min-w-40"
                onChange={(selectedOptions: any) => {
                  setCarrier(selectedOptions.map((option: any) => option.value));
                }}
              />
            </div>
            <div className="flex gap-2 items-center mt-2">
              <span className="font-bold">Search By TNumber:</span>
              <input
                type="search"
                className="border-slate-300 border-[1px] p-1 h-[38px] rounded"
                onChange={handleSearchChange}
                value={search}
                placeholder="Search"
              />
              <button
                className={twMerge(
                  'bg-sky-600 hover:bg-sky-500 text-white p-2 h-[38px] rounded',
                  loading && 'bg-gray-300 hover:bg-gray-300'
                )}
                onClick={handleSearch}
                disabled={loading}
              >
                {loading ? 'Searching...' : 'Search'}
              </button>
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    display: 'none'
                  })
                }}
                options={shipFromOptions}
                isSearchable
                isClearable
                isMulti
                placeholder="Ship From"
                className="min-w-40"
                onChange={(selectedOptions: any) => {
                  setShipFrom(selectedOptions.map((option: any) => option.value));
                }}
              />
              <Select
                styles={{
                  control: (provided) => ({
                    ...provided,
                    display: 'none'
                  })
                }}
                options={shipToOptions}
                isSearchable
                isClearable
                isMulti
                placeholder="Ship To"
                className="min-w-40"
                onChange={(selectedOptions: any) => {
                  setShipTo(selectedOptions.map((option: any) => option.value));
                }}
              />
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
          </div>
          <div className="flex flex-col">
            <button
              className="bg-sky-600 hover:bg-sky-500 text-white p-2 h-[38px] rounded mb-2 whitespace-nowrap min-w-24"
              disabled={exporting}
              onClick={handleExport}
            >
              {exporting ? 'Exporting...' : 'Export'}
            </button>
          </div>
        </div>

        <table className="table-auto border-slate-300 border-[1px] w-full">
          <thead className="border-red-400 border-b-[3px]">
          <tr>
            <th className="p-2 cursor-pointer" onClick={() => handleHeaderClick(0)}>
              BOL#
              {sortColumn === 0 &&
                  (sortDirection === 0 ? <ExpandMoreIcon/> : <ExpandLessIcon/>)}
            </th>
            <th className="p-2">PO#</th>
            <th
                className="p-2 cursor-pointer"
                title="Shipment Status"
                onClick={() => handleHeaderClick(2)}
            >
              Status
              {sortColumn === 2 &&
                  (sortDirection === 0 ? <ExpandMoreIcon/> : <ExpandLessIcon/>)}
            </th>
            <th className="p-2" title="Exception">
              Ex.
            </th>
            <th className="p-2 cursor-pointer" onClick={() => handleHeaderClick(15)}>
              Driver Assigned
              {sortColumn === 15 &&
                  (sortDirection === 0 ? <ExpandMoreIcon/> : <ExpandLessIcon/>)}
            </th>
            <th
                className="p-2 whitespace-nowrap"
                title="Checked In"
                // onClick={() => handleHeaderClick(3)}
            >
              C-I
              {/* {sortColumn === 3 &&
                  (sortDirection === 0 ? <ExpandMoreIcon /> : <ExpandLessIcon />)} */}
            </th>
            <th
                className="p-2 cursor-pointer"
                title="Shipper Signature"
                onClick={() => handleHeaderClick(5)}
            >
              Ship Sig
              {sortColumn === 5 &&
                  (sortDirection === 0 ? <ExpandMoreIcon/> : <ExpandLessIcon/>)}
            </th>
            <th
                className="p-2 cursor-pointer"
                title="Driver Signature"
                onClick={() => handleHeaderClick(6)}
            >
              Drv Sig
              {sortColumn === 6 &&
                  (sortDirection === 0 ? <ExpandMoreIcon/> : <ExpandLessIcon/>)}
            </th>
            <th
                className="p-2 whitespace-nowrap"
                title="Checked Out"
                // onClick={() => handleHeaderClick(4)}
            >
              C-O
              {/* {sortColumn === 4 &&
                  (sortDirection === 0 ? <ExpandMoreIcon /> : <ExpandLessIcon />)} */}
            </th>
            <th className="p-2 cursor-pointer" onClick={() => handleHeaderClick(7)}>
              Ship From
              {sortColumn === 7 &&
                  (sortDirection === 0 ? <ExpandMoreIcon/> : <ExpandLessIcon/>)}
            </th>
            <th className="p-2 cursor-pointer" onClick={() => handleHeaderClick(8)}>
              Ship To
              {sortColumn === 8 &&
                  (sortDirection === 0 ? <ExpandMoreIcon/> : <ExpandLessIcon/>)}
            </th>

            <th className="p-2 cursor-pointer" onClick={() => handleHeaderClick(16)}>
              Direction
              {sortColumn === 16 &&
                  (sortDirection === 0 ? <ExpandMoreIcon/> : <ExpandLessIcon/>)}
            </th>

            <th className="p-2 cursor-pointer" onClick={() => handleHeaderClick(9)}>
              Appt. Time (Local)
              {sortColumn === 9 &&
                  (sortDirection === 0 ? <ExpandMoreIcon/> : <ExpandLessIcon/>)}
            </th>
            <th className="p-2 cursor-pointer" onClick={() => handleHeaderClick(10)}>
              Carrier
              {sortColumn === 10 &&
                  (sortDirection === 0 ? <ExpandMoreIcon/> : <ExpandLessIcon/>)}
            </th>
            <th className="p-2" title="Message">
              MSG
            </th>
            <th className="p-2 cursor-pointer" onClick={() => handleHeaderClick(14)}>
              Photos
              {sortColumn === 14 &&
                  (sortDirection === 0 ? <ExpandMoreIcon/> : <ExpandLessIcon/>)}
            </th>
          </tr>
          </thead>
          {!loading && (
              <tbody className="text-center">
              {shipments.map((shipment: ShipmentType) => (
                <Row key={shipment.tnumber} shipment={shipment} />
              ))}
            </tbody>
          )}
        </table>

        {!loading && (
          <div className="flex justify-between mt-2">
            {page > 1 && (
              <button className="hover:text-slate-400" onClick={() => setPage(page - 1)}>
                {'<'} Prev
              </button>
            )}
            {renderPageNumbers()}
            {page < totalPages && (
              <button className="hover:text-slate-400" onClick={() => setPage(page + 1)}>
                Next {'>'}
              </button>
            )}
          </div>
        )}

        {loading && (
          <div className="flex flex-col items-center mt-4">
            <CircularProgress />
            <p className="mt-2">Loading...</p>
          </div>
        )}
      </div>
    </div>
  );
};

interface RowProps {
  shipment: ShipmentType;
}

const Row = ({ shipment }: RowProps) => {
  const handleClick = useLinkClickHandler(`/shipments/${shipment.id}`);
  let statusColor = 'text-green-500';
  let statusText = 'In Transit';
  if (shipment.shipment_status === ShipmentStatusType.truckFound) {
    statusColor = 'text-yellow-500';
    statusText = 'Truck Found';
  } else if (shipment.shipment_status === ShipmentStatusType.canceled) {
    statusColor = 'text-red-500';
    statusText = 'Canceled';
  }

  return (
      <tr
          onClick={(e: any) => handleClick(e)}
          className="hover:bg-[#f5f5f5] border-slate-200 border-b-[1px] cursor-pointer odd:bg-[#f9f9f9] last:border-b-0"
      >
        <td className="p-2">{shipment.tnumber}</td>
        <td className="p-2"></td>
        <td className="p-2" title={statusText}>
          <CircleIcon className={statusColor}/>
        </td>
        <td className="p-2">
          <CircleIcon className="text-green-500"/>
        </td>
        <td className="p-2" title={shipment.driver_name}>
          {shipment.driver_name ? <CheckIcon className="text-green-500"/> : null}
        </td>
        <td className="p-2">
          {shipment.check_in ? <CheckIcon className="text-green-500"/> : null}
        </td>
        <td className="p-2">
          {shipment.ship_sig ? <CheckIcon className="text-green-500"/> : null}
        </td>
        <td className="p-2">
          {shipment.drv_sig ? <CheckIcon className="text-green-500"/> : null}
        </td>
        <td className="p-2">
          {shipment.check_out ? <CheckIcon className="text-green-500"/> : null}
        </td>
        <td className="p-2">{shipment.ship_from}</td>
        <td className="p-2">{shipment.ship_to}</td>
        <td className="p-2">{shipment.direction === 'inbound' ? 'IB':'OB'}</td>
        <td className="p-2">
          {' '}
          {new Date(shipment.estimated_bol_date).toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit',
            hour: 'numeric',
            minute: 'numeric'
          })}
        </td>
        <td className="p-2">{shipment.carrier_name}</td>

        <td className="p-2"></td>
        <td className="p-2">{shipment.photos_count}</td>
      </tr>
  );
};

export default Shipments;
